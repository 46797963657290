import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginDto, LoginClient } from 'src/shared/service-proxies/service-proxies';
import { Router } from '@angular/router';
import { JwtHolder } from './jwt-holder';


@Injectable()
export class AuthService {
    constructor(private _loginClient: LoginClient, private router:Router, private jwtHolder:JwtHolder) {
    }

    public isAuthenticated(): boolean {
        const token = this.jwtHolder.GetToken();
        const helper = new JwtHelperService();
        
        try
        {
            let isExpired = helper.isTokenExpired(token)

            if(isExpired){
                this.jwtHolder.ClearToken();
                this.jwtHolder.ClearAgentName();
                return false;
            }

            return true;
        }
        catch(err){
            this.jwtHolder.ClearToken();
            this.jwtHolder.ClearAgentName();
            return false;
        }
    }

    public login(credential: LoginDto) : string {
        this._loginClient.login(credential).subscribe(result => {
            this.jwtHolder.SetToken(result.accessToken);
            this.jwtHolder.SetAgentName(credential.agentName);
            this.router.navigate(["/"]);
        }, 
        error => {
            try
            {
            alert('Invalid credentials -' + error.response);
            }
            catch(err)
            {
                alert('Invalid credentials')
            }
        });

        return this.jwtHolder.GetToken();
    }

    public logout():void{
            this.jwtHolder.ClearToken();
            this.router.navigate(["/login"]);   
    }

    public getToken(){
        return this.jwtHolder.GetToken();
    }

    public getAgentName(){
        return this.jwtHolder.GetAgentName();
    }
}