import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/shared/service-proxies/auth-service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;

  constructor(private _authService:AuthService, private router: Router){
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  isAuthenticated (): boolean{
    return this._authService.isAuthenticated();
  }

  getUserName():string{
    return this._authService.getAgentName();
  }

  logout():void{
    this._authService.logout();
    // this.router.navigate(['login']);
  }
}

