import { Component, OnInit } from '@angular/core';
import { CardTypeClient, CreatePaymentTypeDto } from 'src/shared/service-proxies/service-proxies';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-create-card-type',
  templateUrl: './create-card-type.component.html'
})
export class CreateCardTypeComponent implements OnInit {

  paymentType: CreatePaymentTypeDto = new CreatePaymentTypeDto();

  constructor(private _cardtypeClient: CardTypeClient, private activatedRoute: ActivatedRoute, private route: Router) {

  }

  ngOnInit() {

  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      this.paymentType.cardType = Number.parseInt(this.paymentType.cardType.toString());
      this._cardtypeClient.insert(this.paymentType).subscribe(result =>
        this.route.navigate(['card-type'])
      );
    }
  }
}
