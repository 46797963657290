import { Component, OnInit } from '@angular/core';
import { CardTypeClient,  CardTypeRangeUpdateDto, CardTypeRangeClient, CreateCardTypeRangeDto, PaymentTypeDto } from 'src/shared/service-proxies/service-proxies';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-create-card-type',
  templateUrl: './create-card-type-range.component.html'
})
export class CreateCardTypeRangeComponent implements OnInit {

  cardType: PaymentTypeDto = new PaymentTypeDto();
  cardTypeRange: CreateCardTypeRangeDto = new CreateCardTypeRangeDto();
  id:number = 0;

  constructor(private _cardtypeRangeClient: CardTypeRangeClient, private _cardTypeClient: CardTypeClient, private activatedRoute: ActivatedRoute, private route: Router) {
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params.id;
    this._cardTypeClient.getById(this.id).subscribe(result =>{
      this.cardType = result,
      this.cardTypeRange.paymentTypeId =  Number.parseInt(this.id.toString());
    }
    );
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      this._cardtypeRangeClient.insert(this.cardTypeRange).subscribe(result =>
        this.route.navigate(['/card-type-range/' + this.id])
      );
    }
  }
}
