import { Component, OnInit } from '@angular/core';
import { IPaymentTypeDto, CardTypeClient, CreatePaymentTypeDto, CardTypeRangeUpdateDto, CardTypeRangeClient, CreateCardTypeRangeDto, PaymentTypeDto } from 'src/shared/service-proxies/service-proxies';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-edit-card-type',
  templateUrl: './edit-card-type-range.component.html'
})
export class EditCardTypeRangeComponent implements OnInit {

  cardType: PaymentTypeDto = new PaymentTypeDto();
  cardTypeRange: CardTypeRangeUpdateDto = new CardTypeRangeUpdateDto();
  id: number = 0;
  cardTypeRangeId: number = 0;

  constructor(private _cardtypeRangeClient: CardTypeRangeClient, private _cardTypeClient: CardTypeClient, private activatedRoute: ActivatedRoute, private route: Router) {
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params.id;
    this.cardTypeRangeId = this.activatedRoute.snapshot.params.cardTypeRangeId;

    this._cardTypeClient.getById(this.id).subscribe(result => {
        this.cardType = result;
        this._cardtypeRangeClient.getById(this.cardTypeRangeId).subscribe(result2 => {
          this.cardTypeRange = result2;
        })
      }
    );
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      this._cardtypeRangeClient.update(this.cardTypeRange).subscribe(result =>
        this.route.navigate(['/card-type-range/' + this.id])
      );
    }
  }
}
