import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class JwtHolder {


    constructor(private cookieService: CookieService) {
    }

    public SetToken(token: string) : string {
        this.cookieService.set('AuthenticationToken', token);
        return token;
    }

    public GetToken():string{
        return this.cookieService.get('AuthenticationToken');
    }

    public ClearToken():void{
        this.cookieService.delete("AuthenticationToken");
    }

    public SetAgentName(agentName: string) : string{
        this.cookieService.set('AgentName', agentName);
        return agentName;
    }

    public GetAgentName():string{
        return this.cookieService.get('AgentName');
    }

    public ClearAgentName():void{
        this.cookieService.delete("AgentName");
    }
}