import { Component, OnInit } from '@angular/core';
import { AuthorizerDto, FraudScreenerDto, AuthorizerClient, FraudScreenerClient, CreateRoutingRulesDto, CardTypeClient, PaymentTypeDto, RoutingRulesClient } from 'src/shared/service-proxies/service-proxies';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-create-routing-rule',
  templateUrl: './create-routing-rule.component.html',
  styleUrls: ['./create-routing-rule.component.css']
})
export class CreateRoutingRuleComponent implements OnInit {

  authorizers: AuthorizerDto[] = new Array();
  fraudScreeners: FraudScreenerDto[] = new Array();
  cardTypes: PaymentTypeDto[] = new Array();
  routingRule: CreateRoutingRulesDto = new CreateRoutingRulesDto();

  constructor(private _routingRuleClient:RoutingRulesClient, private _authorizerClient: AuthorizerClient, private _fraudScreenerClient: FraudScreenerClient, private _cardTypeClient: CardTypeClient, private activatedRoute: ActivatedRoute, private route: Router) {
  }

  ngOnInit() {

    this._authorizerClient.getAll().subscribe(result =>{
      this.authorizers = result;
    })
    
    this._cardTypeClient.getAll().subscribe(result =>{
      this.cardTypes = result
    });

    this._fraudScreenerClient.getAll().subscribe(result =>{
      this.fraudScreeners = result;
    })
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      this.routingRule.authorizerId = Number.parseInt(this.routingRule.authorizerId.toString());
      this.routingRule.cardTypeId = Number.parseInt(this.routingRule.cardTypeId.toString());

      if(this.routingRule.fraudScreenerId != null)
        this.routingRule.fraudScreenerId = Number.parseInt(this.routingRule.fraudScreenerId.toString());
      else
        this.routingRule.fraudScreenerId = 0;

      this._routingRuleClient.insert(this.routingRule).subscribe(result =>
        this.route.navigate(['/routing-rule'])
      );
    }
  }

}
