import { Component, OnInit } from '@angular/core';
import { CardTypeRangeClient, CardTypeRangeDto, CardTypeClient, PaymentTypeDto } from 'src/shared/service-proxies/service-proxies';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-card-type-range',
  templateUrl: './card-type-range.component.html',
  styleUrls: ['./card-type-range.component.css']
})
export class CardTypeRangeComponent implements OnInit {


  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<CardTypeRangeDto> = new Subject();

  public cardTypeRangeList: CardTypeRangeDto[];
  public cardType: PaymentTypeDto = new PaymentTypeDto();
  public id: number;

  constructor(private cardTypeRangeClient: CardTypeRangeClient, private cardTypeClient: CardTypeClient, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.id = this.activatedRoute.snapshot.params.id;

    this.cardTypeClient.getById(this.id).subscribe(result => {
      this.cardType = result;

      this.cardTypeRangeClient.getAll().subscribe(result2 => {

        this.cardTypeRangeList = new Array();

        for (let index = 0; index < result2.length; index++) {

          if (result2[index].paymentTypeId == this.cardType.id) {
            this.cardTypeRangeList.push(result2[index]);
          }
        }

        this.dtTrigger.next();
      })
    })
  }

  deleteCardTypeRange(id: number): void {
    if (confirm("Are you sure you want to delete this bin range?")) {
      this.cardTypeRangeClient.delete(id).subscribe(result => {
        this.cardTypeRangeClient.getAll().subscribe(result2 => {

          this.cardTypeRangeList = new Array();

          for (let index = 0; index < result2.length; index++) {

            if (result2[index].paymentTypeId == this.cardType.id) {
              this.cardTypeRangeList.push(result2[index]);
            }
          }
        })
      })
    }
  }
}
