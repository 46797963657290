import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { TransactionClient, TransactionDto } from "src/shared/service-proxies/service-proxies";
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AfterViewInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { JwtHolder } from "src/shared/service-proxies/jwt-holder";
declare function createDateRangePicker(): void;

  class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
    filter: string;
  }

@Component({
  selector: "transaction-list",
  templateUrl: "transactions.component.html",
  styleUrls: ["./transactions.component.css"]
})
export class TransactionsComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(private http: HttpClient, private jwtHolder: JwtHolder) {}
  

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  dtTrigger: Subject<any> = new Subject();

  dtOptions: any = {};

  previousMaxWidth = "";
  transactions: TransactionDto[];
  dtParams: any = {};
  filter = "";
  typeOptions = ["Transaction Date", "Transaction Status", "Payment ID", "Payment Method Code", "Currency Code", "Fraud Status" ]
  typeFilter = "All";

  transactionStatusFilter:number = -1;
  paymentIdFilter:string = "";
  paymentMethodCodeFilter:string = "";
  currencyCodeFilter:string = "";
  fraudStatusFilter:number = -1;


  ngOnInit(): void {

    this.previousMaxWidth = document.getElementById("containerDiv").style.maxWidth;
    document.getElementById("containerDiv").style.maxWidth = "95%";

    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      responsive: true,
      serverSide: true,
      processing: true,
      bSort: false,
      ajax: (dataTablesParameters: any, callback) => {
        that.http
          .post<DataTablesResponse>(
            environment.apiUrl + '/api/Transaction/GetTransactionsDataTable?' + "&startDate=" + window["startDate"] + "&endDate=" + window["endDate"] + '&transactionStatus=' + this.transactionStatusFilter +  '&paymentId=' + this.paymentIdFilter + '&paymentMethodCode=' + this.paymentMethodCodeFilter + '&currencyCode=' + this.currencyCodeFilter + '&fraudStatus=' + this.fraudStatusFilter, 
            dataTablesParameters, {
              headers: { Authorization: "Bearer " + this.jwtHolder.GetToken(), }
            }      
          ).subscribe(resp => {
            that.transactions = resp.data;
            this.dtParams = dataTablesParameters;
            this.filter = resp.filter;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: []
            });
          });
      },
      columns: [{ data: 'id' } ]
    };

    createDateRangePicker();
  }

  ngOnDestroy(): void {
    document.getElementById("containerDiv").style.maxWidth = this.previousMaxWidth;
}

download():void{
    var url = environment.apiUrl + "/api/Transaction/Download?" + "startDate=" + window["startDate"] + "&endDate=" + window["endDate"] + '&transactionStatus=' + this.transactionStatusFilter +  '&     paymentId=' + this.paymentIdFilter + '&paymentMethodCode=' + this.paymentMethodCodeFilter + '&currencyCode=' + this.currencyCodeFilter + '&fraudStatus=' + this.fraudStatusFilter;

      // Change this to use your HTTP client
      fetch(url, { headers: { "Authorization": "Bearer " + this.jwtHolder.GetToken() } } ) // FETCH BLOB FROM IT
        .then((response) => response.blob())
        .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
          var _url = window.URL.createObjectURL(blob);
          window.open(_url, "_blank").focus(); // window.open + focus
      }).catch((err) => {
        console.log(err);
      });
  }

ngAfterViewInit(): void {
    this.dtTrigger.next();  
}

search():void{
  this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    dtInstance.ajax.reload(null, false);
});
}

}
