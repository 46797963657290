import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { ISystemCustomerDto, SystemCustomerClient, SystemCustomerDto } from "src/shared/service-proxies/service-proxies";


@Component({
    selector: 'system-customer-list',
    templateUrl: "system-customer.component.html",
    styleUrls: ["./system-customer.component.css"]
})

export class SystemCustomerComponent implements OnInit {

    constructor(private _systemCustomerClient: SystemCustomerClient) {

    }

    pageTitle: string = "System Customers"
    systemCustomers: SystemCustomerDto[];

    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<SystemCustomerDto> = new Subject();

    ngOnInit(): void {
        this._systemCustomerClient.getAll().subscribe(result => {
            this.systemCustomers = result;
            this.dtTrigger.next();
        });
    }

    _searchFilter: string;
    get searchFilter(): string {
        return this._searchFilter;
    }

    set searchFilter(value: string) {
        this._searchFilter = value;
    }
}



