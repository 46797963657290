import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { IRoutingRulesDto, RoutingRulesClient, RoutingRulesDto } from "src/shared/service-proxies/service-proxies";

@Component({
    selector: 'routing-rule-list',
    templateUrl: "routing-rule.component.html",
    styleUrls: ["./routing-rule.component.css"]
})

export class RoutingRulesComponent implements OnInit {

   constructor(private _routingrulesClient: RoutingRulesClient, private activatedRoute: ActivatedRoute, private router: Router) { 

    }

    pageTitle: string = "Routing Rules"
    routingrules: RoutingRulesDto[];

    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<RoutingRulesDto> = new Subject();

    ngOnInit(): void {
        this._routingrulesClient.getAll().subscribe(result => {
            this.routingrules = result;
            this.dtTrigger.next();
        });
    }

    _searchFilter: string;
    get searchFilter(): string {
        return this._searchFilter;
    }

    set searchFilter(value: string) {
        this._searchFilter = value;
    }

    deleteRoutingRule(cardTypeId:number, countryCode:string, currencyCode:string) : void{
        if(confirm("Are you sure you want to delete this rule?"))
        {
        countryCode = countryCode.replace(" ", "-");
        currencyCode = currencyCode.replace(" ", "-");
        this._routingrulesClient.delete(cardTypeId, countryCode, currencyCode).subscribe(result =>{
            this._routingrulesClient.getAll().subscribe(result2 => {
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    this.router.navigate(['/routing-rule']);
                }); 
            });
        })
    }
    }
}



