import { Component, OnInit } from '@angular/core';
import { LoginDto, LoginClient } from 'src/shared/service-proxies/service-proxies';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/shared/service-proxies/auth-service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public credential: LoginDto = new LoginDto();
  
  constructor(private _authService: AuthService, private activatedRoute: ActivatedRoute, private route: Router) {

  }

  ngOnInit() {
    this.credential.agentName = "";
    this.credential.passWord = "";
    this.credential.domainCode = "";
  }

  isAuthenticated():boolean{
    var isAuthenticated =  this._authService.isAuthenticated();
    return isAuthenticated;
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      try {
        this._authService.login(this.credential)
      }
      catch (exc) {
        alert('Invalid credentials');
      }
    }
  }
}
