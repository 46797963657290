import { HttpHeaders } from "@angular/common/http";
import { JwtHolder } from "./jwt-holder";

export class ClientBase {

    constructor(private jwtHolder:JwtHolder) {
    }
  
      protected transformOptions(options: any) { 
  
        if(this.jwtHolder.GetToken()){
            options.headers = options.headers.append("Authorization", "Bearer " + this.jwtHolder.GetToken());
        }
  
        return Promise.resolve(options); 
      } 
  }