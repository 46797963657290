import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { IPaymentTypeDto, CardTypeClient, PaymentTypeDto } from "src/shared/service-proxies/service-proxies";

@Component({
    selector: 'card-type-list',
    templateUrl: "card-type.component.html",
    styleUrls: ["./card-type.component.css"]
})

export class CardTypeComponent implements OnInit {

    constructor(private _cardtypeClient: CardTypeClient) {

    }

    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<PaymentTypeDto> = new Subject();

    pageTitle: string = "Card Types"
    cardtypes: PaymentTypeDto[];

    ngOnInit(): void {
        this._cardtypeClient.getAll().subscribe(result => {
            this.cardtypes = result;
            this.dtTrigger.next();
        });
    }

    _searchFilter: string;
    get searchFilter(): string {
        return this._searchFilter;
    }

    set searchFilter(value: string) {
        this._searchFilter = value;
    }

    deleteCardType(id: number): void {
        if (confirm("Are you sure you want to delete this Card Type?")) {
            this._cardtypeClient.delete(id).subscribe(result => {
                this._cardtypeClient.getAll().subscribe(result2 => {
                    this.cardtypes = result2;
                });
            })
        }
    }

    getTypeName(id:number):string{
        if(id == 0){
            return "Credit card"
        }
        else if(id == 1){
            return "Debit card"
        }
        else if(id == 2){
            return "Offline payment"
        }
    }
}



