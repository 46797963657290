import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ServiceProxyModule } from 'src/shared/service-proxies/service-proxy.module';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { SystemCustomerComponent } from './system-customer/system-customer.component';
import { AuthorizerComponent } from './authorizer/authorizer.component';
import { FraudScreenerComponent } from './fraud-screener/fraud-screener.component';
import { CardTypeComponent } from './card-type/card-type.component';
import { RoutingRulesComponent } from './routing-rule/routing-rule.component';
import { EditCardTypeComponent } from './card-type/edit-card-type.component';
import { CreateCardTypeComponent } from './card-type/create-card-type.component';
import { CardTypeRangeComponent } from './card-type-range/card-type-range.component';
import { CreateCardTypeRangeComponent } from './card-type-range/create-card-type-range.component';
import { EditCardTypeRangeComponent } from './card-type-range/edit-card-type-range.component';
import { CreateRoutingRuleComponent } from './routing-rule/create-routing-rule.component';
import { EditRoutingRuleComponent } from './routing-rule/edit-routing-rule.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from 'src/shared/service-proxies/auth-guard-service';
import { CookieService } from 'ngx-cookie-service';
import { DataTablesModule } from 'angular-datatables';
import { TransactionsComponent } from './transaction/transactions.component';


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    TransactionsComponent,
    SystemCustomerComponent,
    AuthorizerComponent,
    FraudScreenerComponent,
    CardTypeComponent,
    RoutingRulesComponent,
    EditCardTypeComponent,
    CreateCardTypeComponent,
    CardTypeRangeComponent,
    CreateCardTypeRangeComponent,
    EditCardTypeRangeComponent,
    CreateRoutingRuleComponent,
    EditRoutingRuleComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ServiceProxyModule,
    DataTablesModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuardService] },
      { path: 'home', component: HomeComponent , canActivate: [AuthGuardService] },
      { path: 'transactions', component: TransactionsComponent, canActivate: [AuthGuardService] },
      { path: 'system-customer', component: SystemCustomerComponent, canActivate: [AuthGuardService] },
      { path: 'authorizer', component: AuthorizerComponent, canActivate: [AuthGuardService] },
      { path: 'fraud-screener', component: FraudScreenerComponent, canActivate: [AuthGuardService] },
      { path: 'card-type', component: CardTypeComponent, canActivate: [AuthGuardService] },
      { path: 'create-card-type', component: CreateCardTypeComponent, canActivate: [AuthGuardService] },
      { path: 'edit-card-type/:id', component: EditCardTypeComponent, canActivate: [AuthGuardService]},
      { path: 'routing-rule', component: RoutingRulesComponent, canActivate: [AuthGuardService] },
      { path: 'create-routing-rule', component: CreateRoutingRuleComponent, canActivate: [AuthGuardService] },
      { path: 'edit-routing-rule/:cardTypeId/:countryCode/:currencyCode', component: EditRoutingRuleComponent, canActivate: [AuthGuardService]},
      { path: 'card-type-range/:id', component: CardTypeRangeComponent, canActivate: [AuthGuardService]},
      { path: 'create-card-type-range/:id', component: CreateCardTypeRangeComponent, canActivate: [AuthGuardService]},
      { path: 'edit-card-type-range/:id/:cardTypeRangeId', component: EditCardTypeRangeComponent, canActivate: [AuthGuardService]},
      { path: 'login', component:LoginComponent}
    ]),
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
