import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { IFraudScreenerDto, FraudScreenerClient, FraudScreenerDto } from "src/shared/service-proxies/service-proxies";


@Component({
    selector: 'fraud-screener-list',
    templateUrl: "fraud-screener.component.html",
    styleUrls: ["./fraud-screener.component.css"]
})

export class FraudScreenerComponent implements OnInit {

    constructor(private _fraudScreenerClient: FraudScreenerClient) {

    }
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<FraudScreenerDto> = new Subject();

    pageTitle: string = "Fraud Screeners"    
    fraudScreeners: FraudScreenerDto[];

    ngOnInit(): void {
        this._fraudScreenerClient.getAll().subscribe(result => {
            this.fraudScreeners = result;
            this.dtTrigger.next();
        });
    }

    _searchFilter: string;
    get searchFilter(): string {
        return this._searchFilter;
    }

    set searchFilter(value: string) {
        this._searchFilter = value;
    }
}



