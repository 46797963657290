import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { IAuthorizerDto, AuthorizerClient, AuthorizerDto } from "src/shared/service-proxies/service-proxies";


@Component({
    selector: 'authorizer-list',
    templateUrl: "authorizer.component.html",
    styleUrls: ["./authorizer.component.css"]
})

export class AuthorizerComponent implements OnInit {

    constructor(private _authorizerClient: AuthorizerClient) {

    }

    pageTitle: string = "Authorizers"
    authorizers: AuthorizerDto[];
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<AuthorizerDto> = new Subject();

    ngOnInit(): void {
        this.dtOptions = {
            pagingType: 'full_numbers',
          };

        this._authorizerClient.getAll().subscribe(result => {
            this.authorizers = result;
            this.dtTrigger.next();
        });
    }

    _searchFilter: string;
    get searchFilter(): string {
        return this._searchFilter;
    }

    set searchFilter(value: string) {
        this._searchFilter = value;
    }
}



