import { NgModule } from '@angular/core';

import * as ApiServiceProxies from './service-proxies';
import { API_BASE_URL } from './service-proxies';
import { AuthService } from './auth-service';
import { AuthGuardService } from './auth-guard-service';
import { environment } from 'src/environments/environment';
import { JwtHolder } from './jwt-holder';
import { CookieService } from 'ngx-cookie-service';


@NgModule({
    providers: [
        ApiServiceProxies.LoginClient,        
        ApiServiceProxies.CardTypeRangeClient,        
        ApiServiceProxies.SystemCustomerClient,
        ApiServiceProxies.AuthorizerClient,
        ApiServiceProxies.FraudScreenerClient,        
        ApiServiceProxies.CardTypeClient,
        ApiServiceProxies.RoutingRulesClient,
        ApiServiceProxies.TransactionClient,
        AuthService,
        AuthGuardService,
        JwtHolder,
        CookieService,
        { provide: API_BASE_URL, useValue: environment.apiUrl }
    ]
})

export class ServiceProxyModule { }