import { Component, OnInit } from '@angular/core';
import { CardTypeClient, IPaymentTypeDto, PaymentTypeDto, PaymentTypeUpdateDto, IPaymentTypeUpdateDto } from 'src/shared/service-proxies/service-proxies';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-edit-card-type',
  templateUrl: './edit-card-type.component.html',
  styleUrls: ['./edit-card-type.component.css']
})
export class EditCardTypeComponent implements OnInit {

  public paymentType:PaymentTypeUpdateDto = new PaymentTypeUpdateDto();
  id:number;

  constructor(private _cardtypeClient: CardTypeClient, private activatedRoute: ActivatedRoute, private route: Router) { 
    
  }

  ngOnInit() {
    
      this.id = this.activatedRoute.snapshot.params.id;

      this._cardtypeClient.getById(this.id).subscribe(result => 
        this.paymentType = result,
      );

  }

  onSubmit(form:NgForm){
    this.paymentType.cardType = Number.parseInt(this.paymentType.cardType.toString());
    this._cardtypeClient.update(this.paymentType).subscribe(result =>
      this.route.navigate(['card-type'])
    );
  }
}
