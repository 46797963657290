import { Component, OnInit } from '@angular/core';
import { AuthorizerDto, FraudScreenerDto, PaymentTypeDto, CreateRoutingRulesDto, RoutingRulesClient, AuthorizerClient, FraudScreenerClient, CardTypeClient, RoutingRulesUpdateDto, RoutingRulesDto } from 'src/shared/service-proxies/service-proxies';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-edit-routing-rule',
  templateUrl: './edit-routing-rule.component.html',
  styleUrls: ['./edit-routing-rule.component.css']
})
export class EditRoutingRuleComponent implements OnInit {

  authorizers: AuthorizerDto[] = new Array();
  fraudScreeners: FraudScreenerDto[] = new Array();
  cardTypes: PaymentTypeDto[] = new Array();
  routingRule: RoutingRulesUpdateDto = new RoutingRulesUpdateDto();
  cardTypeId:number;
  countryCode:string;
  currencyCode:string;
  previousRoutingRule:RoutingRulesDto = new RoutingRulesDto();

  constructor(private _routingRuleClient:RoutingRulesClient, private _authorizerClient: AuthorizerClient, private _fraudScreenerClient: FraudScreenerClient, private _cardTypeClient: CardTypeClient, private activatedRoute: ActivatedRoute, private route: Router) {
  }

  ngOnInit() {

    this.cardTypeId = this.activatedRoute.snapshot.params.cardTypeId;
    this.countryCode = this.activatedRoute.snapshot.params.countryCode;
    this.currencyCode = this.activatedRoute.snapshot.params.currencyCode;

    this._authorizerClient.getAll().subscribe(result =>{
      this.authorizers = result;

      this._fraudScreenerClient.getAll().subscribe(result2 => {
        this.cardTypes = result2;

        this._cardTypeClient.getAll().subscribe(result3 =>{
          this.cardTypes = result3

          this._fraudScreenerClient.getAll().subscribe(result4 =>{
            this.fraudScreeners = result4;

            let countryCodeSanitized = this.countryCode.replace(" ", "-");
            let currencyCodeSanitized = this.currencyCode.replace(" ", "-");

            this._routingRuleClient.getById(Number.parseInt(this.cardTypeId.toString()), countryCodeSanitized, currencyCodeSanitized).subscribe(result5 =>{
              this.routingRule = new RoutingRulesUpdateDto();
              this.previousRoutingRule = result5;
              this.routingRule.authorizerId = result5.authorizerId;
              this.routingRule.cardTypeId = result5.cardTypeId;
              this.routingRule.countryCode = result5.countryCode;
              this.routingRule.currencyCode = result5.currencyCode;
              this.routingRule.fraudScreenerId = result5.fraudScreenerId;
            })     
          }) 
        });
      })
    })
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      this.routingRule.authorizerId = Number.parseInt(this.routingRule.authorizerId.toString());
      this.routingRule.cardTypeId = Number.parseInt(this.routingRule.cardTypeId.toString());

      if(this.routingRule.fraudScreenerId != null)
        this.routingRule.fraudScreenerId = Number.parseInt(this.routingRule.fraudScreenerId.toString());
      else
        this.routingRule.fraudScreenerId = 0;

      let previousRoutingRuleCountryCodeSanitized = this.previousRoutingRule.countryCode.replace(' ', '-');
      let previousRoutingRuleCurrencyCodeSanitized = this.previousRoutingRule.currencyCode.replace(' ', '-');

      this._routingRuleClient.delete(this.previousRoutingRule.cardTypeId, previousRoutingRuleCountryCodeSanitized, previousRoutingRuleCurrencyCodeSanitized)
      .subscribe(result => {
        this._routingRuleClient.insert(this.routingRule).subscribe(result =>
          this.route.navigate(['/routing-rule'])
        );
      })
    }
  }
}
